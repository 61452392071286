import React from "react";

import { CircularProgress, CircularProgressProps, SxProps } from "@mui/material";

export const LoadingElement = ({
  top = "calc(50% - 40px)",
  left = "calc(50% - 40px)",
  position = "absolute",
  ...props
}: CircularProgressProps & { left?: string, top?: string, position?: string }) =>
  <CircularProgress
    {...props}
    sx={{ ...props.sx, position, top, left } as SxProps}
  />
