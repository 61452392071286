import React from "react"

import { Menu, MenuItem, MenuProps } from "@mui/material"

export interface IMenuElement extends MenuProps {
  options: string[]
  selected?: string
  dense?: boolean
  onClickOption: (option: string, index: number) => void
}

export const MenuElement = ({
  options,
  selected,
  onClickOption,
  dense,
  ...props
}: IMenuElement) => {
  return <Menu
    {...props}
  >
    {options.map((option, i) => {
      return <MenuItem
        key={option}
        dense={dense}
        selected={option === selected}
        onClick={() => onClickOption(option, i)}
      >
        {option}
      </MenuItem>
    })}
  </Menu>
}