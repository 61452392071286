/**
 * Msal helper functions to deal with setting localAccountId, idToken and selected customerOrganization
 * Adds Bearer token to headers for Apollo Client (authContext)
 */

import { FetchResult, NextLink, Observable, Operation } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { AuthenticationResult, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { captureException, captureMessage } from "@sentry/react";

import { loginRequest, msalConfig } from "./authConfig";
import { GET_ORGANIZATIONS } from "../queries";
import { OrganizationNodeConnection } from "../queries/types";

const ERR_NO_LOCAL_ACCOUNT_ID = "Local account id not found";
const ERR_NO_ACCOUNT = "Account not found";

export const getAvailableOrganizations = async () => {
  try {
    const { client } = await import("../services/ApolloClient");
    const { data }: { data: { allOrganizations: OrganizationNodeConnection } } = await client.query({
      query: GET_ORGANIZATIONS,
    });
    return data?.allOrganizations?.edges.map(edge => ({ ...edge?.node, uid: `${edge?.node?.uid}` }));
  } catch (error) {
    captureException(error);
    return [];
  }
};

export const getLocalAccountId = () => sessionStorage.getItem("localAccountId") ?? "";
export const setLocalAccountId = (localAccountId: string) => sessionStorage.setItem("localAccountId", localAccountId)
export const getCustomerOrganization = () => localStorage.getItem(`customerOrganization${getLocalAccountId()}`) ?? "";

export const setCustomerOrganizations = async () => {
  const customerOrganizations = await getAvailableOrganizations();
  if (customerOrganizations.length) {
    /**
     * if there is only one organization, select it.
     * if multiple organizations are found, Select the one in localStorage.
     */
    if (customerOrganizations.length === 1 || !getCustomerOrganization()) {
      localStorage.setItem(
        `customerOrganization${getLocalAccountId()}`,
        customerOrganizations[0].uid,
      );
    }
  } else {
    captureMessage(
      `No organizations found for local account id: ${getLocalAccountId()}.`,
      "warning",
    );
  }
}

export const handleResponse = (response: AuthenticationResult | null) => {
  if (response?.account?.localAccountId && response?.idToken) {
    sessionStorage.setItem("localAccountId", response.account.localAccountId)
    sessionStorage.setItem("idToken", response.idToken)
    setCustomerOrganizations()
  }
}

// configuration parameters are located at authConfig.tsx
export const msalInstance = new PublicClientApplication(msalConfig);

export const selectAccount = () => {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  const currentAccounts = msalInstance.getAllAccounts();
  if (currentAccounts.length === 0) {
    return;
  }
  if (currentAccounts.length > 1) {
    captureMessage("Multiple accounts detected.", "warning");
    return;
  }

  sessionStorage.setItem("localAccountId", currentAccounts[0].localAccountId)
  setCustomerOrganizations()
}
selectAccount()

export const logOut = () => {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */
  const logoutRequest = {
    account: msalInstance.getActiveAccount(),
    postLogoutRedirectUri: msalConfig.auth.redirectUri,
  };
  sessionStorage.removeItem("idToken")
  sessionStorage.removeItem("localAccountId")

  msalInstance.logoutRedirect(logoutRequest);
}

export const getTokenRedirect = () => {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  const localAccountId = getLocalAccountId()
  if (localAccountId === "") {
    return Promise.reject(new Error(ERR_NO_LOCAL_ACCOUNT_ID))
  }
  const account = msalInstance.getAccount({ localAccountId });
  if (!account) {
    return Promise.reject(new Error(ERR_NO_ACCOUNT))
  }

  loginRequest.account = account

  return msalInstance.acquireTokenSilent(loginRequest)
    .then(azureToken => {
      sessionStorage.setItem("idToken", azureToken.idToken)
      return azureToken
    })
    .catch(error => {
      captureMessage("silent token acquisition fails. acquiring token using redirect", "warning")
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(loginRequest)
      }
      captureException(error);
    });
}

/**
 * Apollo link context that adds the Azure B2C access token to requests
 */
export const apolloAuthContext = setContext(async (_, { headers }) => {
  return getTokenRedirect()?.then(azureToken => {
    return {
      headers: {
        ...headers,
        "Authorization": `Bearer ${azureToken?.idToken}`,
      },
    }
  }).catch(error => {
    if (![ERR_NO_LOCAL_ACCOUNT_ID, ERR_NO_ACCOUNT].includes(error.message)) {
      captureException(error)
    }
  });
})


/**
 * Adapted from https://github.com/apollographql/apollo-link/issues/646#issuecomment-423279220
 * Add new token to apollo context and retry operation
 */
export const getObservableTokenHeader = (operation: Operation, forward: NextLink) => {
  return new Observable<FetchResult>(observer => {
    getTokenRedirect()
      .then((azureToken) => {
        operation.setContext(({ headers = {} }) => ({
          headers: {
            // Re-add old headers
            ...headers,
            // Switch out old access token for new one
            authorization: `Bearer ${azureToken?.idToken}`,
          },
        }));
      })
      .then(() => {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };
        // Retry last failed request
        forward(operation).subscribe(subscriber);
      })
      .catch(error => {
        // No refresh or client token available, we force user to login
        observer.error(error);
      });
  });
}
