import React, { ReactElement } from "react";

import { IconButton, IconButtonProps } from "@mui/material";

/** @category Button */
export interface IconButtonElementProps extends IconButtonProps {
  icon?: ReactElement
}

/**
 * Renders wrapper around the [Mui Button](https://mui.com/material-ui/react-button) \
 * Fires onClick when Button is clicked.
 * @param options
 * @category Button
 */
export const IconButtonElement = ({ icon, ...props }: IconButtonElementProps) =>
  <IconButton
    {...props}
  >{icon}</IconButton >;
