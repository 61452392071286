// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:export {
  primaryColor: #007796;
  errorColor: #d9001b;
  warningColor: #f59a23;
  successColor: #63a103;
  backgroundColor: #272524; }

button.MuiButton-root,
.MuiButtonGroup-root button.MuiButton-root {
  text-transform: none;
  font-size: 12px; }
  button.MuiButton-root.MuiButton-contained,
  .MuiButtonGroup-root button.MuiButton-root.MuiButton-contained {
    color: #fff;
    background-color: #00538a;
    border-color: #00538a; }
  button.MuiButton-root.MuiButton-outlined,
  .MuiButtonGroup-root button.MuiButton-root.MuiButton-outlined {
    color: #fff;
    border-color: #363433;
    background-color: #363433; }
  button.MuiButton-root.Mui-disabled,
  .MuiButtonGroup-root button.MuiButton-root.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12); }
`, "",{"version":3,"sources":["webpack://./src/styles/colors.module.scss","webpack://./src/components/Elements/buttonElement.scss"],"names":[],"mappings":"AA2BA;EACE,qBA5BqB;EA6BrB,mBA5BmB;EA6BnB,qBA5BqB;EA6BrB,qBA5BqB;EA6BrB,wBA5BwB,EAAA;;ACF1B;;EAEE,oBAAoB;EACpB,eAAe,EAAA;EAHjB;;IAMI,WDD8B;ICE9B,yBDHuC;ICIvC,qBDFmC,EAAA;ECNvC;;IAYI,WDH6B;ICI7B,qBDHkC;ICIlC,yBDNsC,EAAA;ECR1C;;IAkBI,qCAAqC,EAAA","sourcesContent":["$primary-color: #007796;\n$error-color: #d9001b;\n$warning-color: #f59a23;\n$success-color: #63a103;\n$background-color: #272524;\n$background-color-opacity: rgba(29, 26, 25, 0.85);\n$button-contained-background-color: #00538a;\n$button-contained-text-color: #fff;\n$button-contained-border-color: #00538a;\n\n$button-outlined-background-color: #363433;\n$button-outlined-text-color: #fff;\n$button-outlined-border-color: #363433;\n\n$icon-color: #919191;\n$svg-color: #cdcccc;\n$badge-color: #e04545;\n\n$input-background-color: #1d1a19;\n$table-odd-row-background-color: #1d1a19;\n\n$text-color: #d7d6d6;\n$text-color-light: #282929;\n$link-color: #02a4ff;\n$border-color: #1d1a19;\n\n\n:export {\n  primaryColor: $primary-color;\n  errorColor: $error-color;\n  warningColor: $warning-color;\n  successColor: $success-color;\n  backgroundColor: $background-color;\n}","@import \"../../styles/colors.module.scss\";\n\nbutton.MuiButton-root,\n.MuiButtonGroup-root button.MuiButton-root {\n  text-transform: none;\n  font-size: 12px;\n\n  &.MuiButton-contained {\n    color: $button-contained-text-color;\n    background-color: $button-contained-background-color;\n    border-color: $button-contained-border-color;\n  }\n\n  &.MuiButton-outlined {\n    color: $button-outlined-text-color;\n    border-color: $button-outlined-border-color;\n    background-color: $button-outlined-background-color;\n  }\n\n  &.Mui-disabled {\n    background-color: rgba(0, 0, 0, 0.12);\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
