import React, { useState } from "react";
const LocalizationProvider = React.lazy(
  () => import("@mui/x-date-pickers/LocalizationProvider").then(module => ({
    default: module.LocalizationProvider,
  }))
);
const DatePicker = React.lazy(
  () => import("@mui/x-date-pickers/DatePicker").then(module => ({
    default: module.DatePicker,
  }))
);


import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

/** @category DatePicker */
export interface DatePickerElementProps  {
  /** Initial Date */
  value?: Date,
  /** Triggers when a new date is changed */
  onChange?(date: Date): void
}

/**
 * Renders wrapper around the [Mui DatePicker](https://mui.com/x/react-date-pickers/date-picker/) \
 * uses dateFn adapter for changing [locales](https://mui.com/x/react-date-pickers/date-picker/#localization)
 * @param options
 * @category DatePicker
 */
export const DatePickerElement = ({ value = new Date(), onChange }: DatePickerElementProps) => {
  const [selectedDate, setSelectedDate] = useState(value);
  const onChangeDate = (date: any) => {
    setSelectedDate(date);
    if (onChange) {
      onChange(date);
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={selectedDate}
        onChange={onChangeDate}
        slots={{
          textField: TextField,
        }}
      ></DatePicker>
    </LocalizationProvider>
  )
}