import React from "react"

import { MenuItem, OutlinedSelectProps, Select } from "@mui/material"


import "./selectElement.scss"

export interface ISelectElement extends OutlinedSelectProps {
  options: string[]
  selected?: string
  dense?: boolean
  onClickOption: (option: string, index: number) => void
}

export const SelectElement = ({
  options,
  selected,
  onClickOption,
  dense,
  ...props
}: ISelectElement) => {
  return <Select
    value={selected}
    displayEmpty
    size="small"
    {...props}
  >
    {options.map((option, i) => {
      return <MenuItem
        key={option}
        dense={dense}
        className="select-menu-item"
        onClick={() => onClickOption(option, i)}
        value={option}
      >
        {option}
      </MenuItem>
    })}

  </Select>
}