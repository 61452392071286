import React from "react";

import { Button, ButtonProps } from "@mui/material";

import "./buttonElement.scss";

/**
 * Renders wrapper around the [Mui Button](https://mui.com/material-ui/react-button) \
 * @param options
 * @category Button
 */
export const ButtonElement = ({ name = "Button", variant = "contained", ...props }: ButtonProps) =>
  <Button
    {...props}
    variant={variant}
  >{name}</Button>;
