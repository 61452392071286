import React, { CSSProperties, ReactElement } from "react"

import { combineClassNames } from "../util"

import "./panelElement.scss"

export interface PanelElementProps {
  title?: string
  content?: ReactElement | string
  footer?: ReactElement | string
  style?: CSSProperties
  className?: string
}

export const PanelElement = ({ title, content, footer, style, className }: PanelElementProps) => {
  return <div
    className={combineClassNames(["panel box-shadow", className])}
    style={style}>
    {title && (
      <div className="panel-header">{title}</div>
    )}
    {content && (
      <div className="panel-content">
        {content}
      </div>
    )}
    {footer && (
      <>
        <hr />
        <div className="panel-footer">
          {footer}
        </div>
      </>
    )}
  </div>
}