import { LogLevel, SilentRequest } from "@azure/msal-browser";
import * as Sentry from "@sentry/react";

import { azureAdClientId, azureAdTenantName } from "../config";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 *
 */
export const msalConfig = {
  auth: {
    clientId: azureAdClientId, // This is the ONLY mandatory field that you need to supply.
    authority: `https://${azureAdTenantName}.b2clogin.com/${azureAdTenantName}.onmicrosoft.com/B2C_1a_grideyes_signin`, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [`https://${azureAdTenantName}.b2clogin.com`],
    redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "false", will navigate back to the root.
    clientCapabilities: ["CP1"], // this lets the resource owner know that this client is capable of handling claims challenge.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    /**
     * Below you can configure MSAL.js logs. For more information, visit:
     * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
     */
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          /** Indicates if the log message contains Organizational Identifiable Information (OII) or Personally Identifiable Information (PII). */
          return;
        }
        if ([LogLevel.Error, LogLevel.Warning].includes(level)) {
          Sentry.captureMessage(message, LogLevel[level].toLowerCase() as Sentry.SeverityLevel);
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: SilentRequest = {
  scopes: [azureAdClientId],
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  graphMe: {
    endpoint: "https://graph.microsoft.com/v1.0/me",
    scopes: ["User.Read"],
  },
};
