import React, { CSSProperties, ReactElement } from "react";

import { Switch, FormControlLabel, Tooltip } from "@mui/material";

import "./switchElement.scss";

/** @category Switch */
export interface SwitchElementProps {
  label?: string,
  value?: boolean,
  style?: CSSProperties
  onChange?(e: React.ChangeEvent): void,
  endIcon?: ReactElement,
  tooltipText?: string
}

/**
 * Renders wrapper around the [Mui Switch](https://mui.com/material-ui/react-switch/)
 * @param options
 * @category Switch
 */
export const SwitchElement = ({ label, value = false, style, onChange, endIcon, tooltipText }: SwitchElementProps) => {
  const switchElement = (
    <Tooltip
      title={tooltipText}
      followCursor={true}
    >
      <Switch
        checked={value}
        onChange={onChange}
        onClick={e => e.stopPropagation()}
      />
    </Tooltip>);
  return label ?

    <FormControlLabel
      label={[label, endIcon].map(element => element)}
      control={switchElement}
      labelPlacement="start"
      style={style}
      onClick={e => e.stopPropagation()}
    />
    : switchElement;
}