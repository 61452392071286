/*
 * AppConfig queried by IAM user with AWS CLI and written to file during deployment
 *
 * AppConfig is a polled service, so ideally we would load the config
 * dynamically when loading index or something to that effect. Done statically for now
 * https://github.com/aws-samples/sample-python-helper-aws-appconfig
 */
import appConfigJson from "./default_config.json"
export const appConfig = appConfigJson;

/*
 * Non-AppConfig defined env vars
 */
// MapBox API token - used for retrieving our Mapbox base map style
export const mapboxAccessToken = process.env.MAPBOX_ACCESS_TOKEN ?? "";
// ID for accessing Azure B2C with Azure AD protocol
export const azureAdClientId = process.env.AZURE_AD_CLIENT_ID ?? "";
export const azureAdTenantName = process.env.AZURE_AD_TENANT_NAME ?? "stormgeoadb2cdev";

export const environment = process.env.ENVIRONMENT;
export const killcache = process.env.KILLCACHE;

/*
 * package.json defined env vars
 */
// Project version defined in package.json
export const version = process.env.VERSION;
// Project name defined in package.json
export const projectName = process.env.PROJECT_NAME;

/*
 * Webpack defined env vars
 */
// Output of git rev-parse --short HEAD during webpack build
export const commitHash = process.env.COMMIT_HASH;

