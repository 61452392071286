import React from "react";

import "./lineTypeIndicator.scss";

export interface LineTypeIndicatorProps  {
  width?: string
  height?: string
  color?: string
}

/**
 * Renders colored rectangle for indicating powerline types
 */
export const LineTypeIndicator = ({ width = "1rem", height = ".25rem", color = "#000" }: LineTypeIndicatorProps) =>
  <div
    className="line-type-indicator"
    style={{ height, width, backgroundColor: color }}
  />
