import * as Sentry from "@sentry/react";

import { commitHash, environment, projectName, version } from "./config";

/** [Basic Options](https://docs.sentry.io/platforms/javascript/configuration/options/) */
const sentryOptions = {
  dsn: "https://f9f443a6e69c41ab83ccd1f370ec1456@o1409526.ingest.sentry.io/6746285",
  ...(environment !== "local" && { release: `${projectName}@${version}-${commitHash}` }),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.breadcrumbsIntegration({
      console: false,
    }),
  ],
  environment,
  initialScope: {
    tags: { "commit": commitHash },
  },

  /**
   * Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.\
   * We recommend adjusting this value in production
   */
  tracesSampleRate: environment === "production" ? 0.2 : 1.0,
}

if (environment !== "local") {
  Sentry.init(sentryOptions);
}