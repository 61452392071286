import React from "react"

import { MenuItem, OutlinedSelectProps, Select } from "@mui/material"


import "./selectElement.scss"

export interface ISelectElement extends OutlinedSelectProps {
  options: { name: string, id?: string }[]
  selected?: string
  dense?: boolean
  onClickOption: (option: { name: string, id?: string }) => void
}

export const SelectElement = ({
  options,
  selected,
  onClickOption,
  dense,
  ...props
}: ISelectElement) => {
  return <Select
    value={selected}
    displayEmpty
    size="small"
    {...props}
  >
    {options.map((option) => {
      return <MenuItem
        key={option.name}
        dense={dense}
        className="select-menu-item"
        onClick={() => onClickOption(option)}
        value={option.name}
      >
        {option.name}
      </MenuItem>
    })}

  </Select>
}