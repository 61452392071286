import React, { ReactNode } from "react";
import { Accordion, AccordionSummary, AccordionDetails, SxProps } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";

import "./accordionElement.scss";

/** @category Accordion */
export interface ElementProps {
  /** Text or element to display in header */
  header?: string | ReactNode,
  /** Text or element to display when accordion is expanded */
  content?: string | ReactNode,
  /** Styling to apply to root accordion element */
  sx?: SxProps,
  className?: string
}

/**
 * If expanded is true, it requires an onChange handler to control the expanded value externally.
 * @category Accordion
 */
export type Expander = {
  expanded: true,
  onChange(): void
} | {
  expanded?: false,
  onChange?(): void
};

/** @category Accordion */
export type AccordionElementProps = ElementProps & Expander
/**
 * Renders wrapper around the [Mui Accordion](https://mui.com/material-ui/react-accordion/) \
 * Fires onChange when accordion header is clicked.
 * @param options
 * @category Accordion
 */
export const AccordionElement = ({
  header,
  content,
  sx,
  className,
  expanded,
  onChange,
}: AccordionElementProps) =>
  <Accordion
    disableGutters
    expanded={expanded}
    onChange={onChange}
    sx={sx}
    className={className}
  >
    <AccordionSummary
      expandIcon={<FontAwesomeIcon
        icon={faAngleDown}
        fontSize="large"
      />}
    >
      {header}
    </AccordionSummary>
    <AccordionDetails sx={{ p: 0 }}>
      {content}
    </AccordionDetails>
  </Accordion>