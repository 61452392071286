import React, { Suspense } from "react";

import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { captureEvent } from "@sentry/react";
import { createRoot } from "react-dom/client";
import { HashRouter } from "react-router-dom";

import { handleResponse, msalInstance } from "./auth/authHandler";
import { LoadingElement } from "./components/Elements";
import "./sentry";

msalInstance.initialize().then(async () => {
  /**
   * A promise handler needs to be registered for handling the
   * response returned from redirect flow. If user is already logged in, this will not run.
   * For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
   * https://github.com/Azure-Samples/ms-identity-javascript-v2/blob/master/app/authRedirect.js
   */
  await msalInstance.handleRedirectPromise()
    .then(handleResponse)
    .catch((error) => {
      captureEvent(error)
    });

  /* sessionStorage.localAccountId is available now which is required for StoreProvider and App, so we lazy load them now */
  const { StoreProvider } = await import("./providers/StoreProvider")
  const { default: App } = await import("./App")

  const root = createRoot(document.getElementById("root")!);

  root.render(
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Suspense fallback={<LoadingElement />}>
          <StoreProvider>
            <HashRouter>
              <App />
            </HashRouter>
          </StoreProvider>
        </Suspense>
      </MsalAuthenticationTemplate>
    </MsalProvider>,
  );
});
